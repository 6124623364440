module.exports = [{
      plugin: require('../node_modules/@petlabco/builder-checkout-upsell-integration/gatsby-browser.js'),
      options: {"plugins":[],"builderApiKey":"74b51c0d98ab41b78b3a8b35775045ba","upsellApiUrl":"https://cdn.builder.io/api/v2/content/upsell","upsellSelectorApiUrl":"https://cdn.builder.io/api/v2/content/upsell-selector","checkoutApiUrl":"https://checkout.thepetlabco.com","checkoutApiKey":"6620b2ed-1a5c-41a0-9ffc-264d24bf34f1","checkoutStoreName":"deamplify.myshopify.com","checkoutApiUrlV2":"https://checkout-api.thepetlabco.com","checkoutApiKeyV2":"QBVLhipCwp8D36HrYHEVK2XeVcNytLCBaBofHAbr"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5XDGQT2","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MMRJ7684","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
